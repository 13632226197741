@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');


/* .mq-root-block, .mq-math-mode .mq-root-block{
    border: 1px solid #d9d9d9;
    max-width: 100% ;
    margin: 0;
    padding: 10px 11px !important;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    background-color: #ffffff !important;
    border-radius: 6px;
    transition: all 0.2s ease-in-out !important;
}

.mq-editable-field, .mq-math-mode .mq-editable-field {
    width: 100%;
}

.mq-root-block{
    width: 100%;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

}
.mq-root-block:hover{
    border: 1px solid rgb(23, 23, 23);
}
.mq-root-block:active{
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.584) !important;
}




.input-row-col .mq-root-block{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}










:where(.css-dev-only-do-not-override-byeoj0).ant-collapse>.ant-collapse-item >.ant-collapse-header {
    align-items: flex-start;
}


#math-display .mq-root-block,#math-display  .mq-math-mode .mq-root-block{
        border: none;
        background-color: transparent !important;
        padding: 0 !important;
        cursor: pointer;
}

#math-display .mq-root-block:hover{
    border: none !important;
}
#math-display .mq-root-block:active{
    box-shadow: none !important;
}




  */





.mq-root-block{
    min-height: 30px;
    width: 100%;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;


}
.mq-math-mode var{
    font-family: 'PT Serif', serif !important;
    font-style: normal !important;
}

.mq-editable-field, .mq-math-mode .mq-editable-field {
    width: 100%;
}

.mq-root-block, .mq-math-mode .mq-root-block{
    border: 1px solid #d9d9d9;
    padding: 10px 11px;
    border-radius: 6px;
    background-color: #fff;
    transition: all 0.2s ease-in-out !important;
}



#math-display .mq-root-block,#math-display  .mq-math-mode .mq-root-block{
    border: none;
    background-color: transparent !important;
    padding: 0 !important;
    cursor: pointer;
}

#math-display .mq-root-block{
    font-size: 15px;
}

#math-display .mq-root-block:hover{
border: none !important;
}
#math-display .mq-root-block:active{
box-shadow: none !important;
}




:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.ant-form-item-label {
    width: 70px;
    text-align: left !important; 
  }

  .ant-form-item-control {
    flex: 1;
  }

  @media (width < 576px){
    .ant-form-item-label {
        display: none !important;
    }
  }


  .equation-editor-container {
    position: relative;
  }

  .equation-editor-placeholder {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translate(0,-50%);
    color: #bfbfbf;
  }



  .middle-part .mq-root-block{
    font-size: 14px;
  }

  :where(.css-byeoj0).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-byeoj0).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    position: fixed;
  }




  .middle-part .mq-root-block{
    border: none;
    background-color: transparent;
    padding-left: 0;
  }